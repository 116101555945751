import sysConf from "@/config/config.json"
class Config {
    /**
     * 获取默认请求域名
     * 
     * @returns 
     */
    static getSysUrl() {
        return sysConf.hostUrl
    }
}

export default Config