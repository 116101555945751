<template>
  <div id="dplayers"></div>
</template>

<script lang="ts">
import DPlayer from "dplayer";

import { defineComponent, getCurrentInstance } from "vue";
export default defineComponent({
  name: "dPlayers",
  setup() {
    const { proxy }: any = getCurrentInstance();
    let dp: any = "";
    return {
      proxy,
      dp,
    };
  },
  data() {
    return {
      urlsun: 1,
      urlsunlist: [
        require("../../assets/webm/sp1.webm"),
        require("../../assets/webm/sp2.webm"),
        require("../../assets/webm/sp3.webm"),
        require("../../assets/webm/sp4.webm"),
      ],
    };
  },
  created() {},
  mounted() {
    let container = document.getElementById("dplayers");
    const dp = new DPlayer({
      container: container,
      autoplay: true,
      theme: "#FADFA3",
      loop: true,
      lang: "zh-cn",
      preload: "auto",
      // logo: 'logo.png',
      volume: 5,
      video: {
        // url: "https://d1--cn-gotcha208.bilivideo.com/live-bvc/532934/live_50329118_9516950_1500/index.m3u8?expires=1629434550&len=0&oi=2028359561&pt=web&qn=0&trid=10075ef40241c42f49d69ac5299c52969c31&sigparams=cdn,expires,len,oi,pt,qn,trid&cdn=cn-gotcha08&sign=d4a002eeced8e2793c9cfd7709e07e1c&p2p_type=1&src=9&sl=9&free_type=0&sk=c9c6154426932efa80d25af02e87a3bd",
        // url: "rtmp://192.168.1.251/red5Demo/Yaheen/yaheen",
        url: require("../../assets/webm/sp1.webm"),
        // type: "hls",
      },
      contextmenu: [],
      live: true,
    });
    this.dp = dp;
    this.dp.on("ended", () => {
      this.dp.switchVideo({
        url: this.urlsunlist[this.urlsun],
        // pic: "dplayer.png",
        type: "auto",
      });
      if (this.urlsun < 3) {
        this.urlsun++;
      } else {
        this.urlsun = 0;
      }
      setTimeout(() => {
        this.dp.play();
      }, 500);
    });

    // 禁止右键下载视频
    // document.oncontextmenu = new Function("event.returnValue=false;");
    // document.onselectstart = new Function("event.returnValue=false;");

    // 修改循环播放显示
    document.getElementsByClassName("dplayer-setting-item dplayer-setting-loop")[0];
    // .getElementsByClassName("dplayer-label")[0].innerText = "循环播放";
    // 修改倍速播放显示
    document.getElementsByClassName("dplayer-setting-item dplayer-setting-speed")[0];
    // .getElementsByClassName("dplayer-label")[0].innerText = "播放倍速";
  },
  methods: {
    close() {
      this.dp.destroy();
    },
  },
  components: {},
});
</script>

<style lang="scss" scoped>
#dplayers {
  z-index: 1000;
}
</style>
