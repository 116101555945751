<template>
  <div class="webgl-view">
    <Hero3d @popup="popup" ref="hero3d" />
    <a class="btn-back-" @click="navBack">返回</a>
    <PopupView
      v-if="popupCode != null"
      v-bind:popupCode="popupCode"
      @close="popupCode = null"
    />
  </div>
</template>

<script>
import Hero3d from "@/components/webgl/yaheen/Hero3d.vue";
import PopupView from "@/components/webgl/yaheen/PopupView.vue";

import { Landing3dViewer } from "@/library/landing3d.module";

export default {
  name: "App",
  components: {
    Hero3d,
    PopupView,
  },
  data: () => {
    return {
      popupCode: null,
      detectInfo: "",
    };
  },
  methods: {
    popup: function (evt) {
      this.popupCode = evt.popup;
    },
    navBack: function () {
      this.$refs.hero3d.navBack();
    },
  },
  mounted: function () {
    let md = Landing3dViewer.mobileDetect;
    this.detectInfo = JSON.stringify({
      mobile: md.mobile(),
      mobileGrade: md.mobileGrade(),
      os: md.os(),
      phone: md.phone(),
      tablet: md.tablet(),
      dpr: window.devicePixelRatio,
      threeVersion: Landing3dViewer.threeVersion,
    });
  },
};
</script>

<style lang="scss" scoped>
.detect-info- {
  height: 4em;
  word-break: break-word;
}
.webgl-view {
  zoom: 1;
  width: 573px;
  height: 367px;
  margin-left: 150px;
  .btn-back- {
    position: absolute;
    top: 85em;
    left: 6.5em;
    margin-left: 100px;
    text-decoration: underline;
  }
}
</style>
