<template>
  <div class="hero-1-"></div>
</template>

<script>
import { Landing3dViewer } from "@/library/landing3d.module";
import Config from "@/library/Config";
export default {
  mounted: function () {
    this.v3d_ = new Landing3dViewer({
      prefixAsset: `${Config.getSysUrl()}/assets/webgl/yaheen`,
      container: this.$el,
    });
    this.v3d_.on("popup", (evt) => {
      this.$emit("popup", evt);
    });
    this.v3d_.startup();
  },
  methods: {
    navBack: function () {
      this.v3d_.navBack();
    },
  },
  beforeUnmount: function () {
    this.v3d_.dispose();
  },
};
</script>

<style lang="scss" scoped>

div.hero-1- {
  width: 603px;
  height: 22em;
  /* height: 56.25vw; */
  /* background-color: aqua; */
  &:focus {
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
}
</style>