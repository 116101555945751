<template>
  <el-dialog
    :model-value="visible"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="close()"
    width="75em"
    height="auto"
    top="5vh"
    center
  >
    <div class="popup-1-">
      <h3 v-if="popupCode == `POI-TV-1`"><d-player ref="dPlayer" /></h3>
      <h3 v-else-if="popupCode == `POI-MC-1`">
        <ezuikit-js />
      </h3>
      <h3 v-else-if="popupCode == `POI-QW-1`"></h3>
      <h3 v-else-if="popupCode == `POI-SF-1`"></h3>
      <h3 v-else-if="popupCode == `POI-TT-1`"></h3>
      <!-- <button class="btn-close-" @click="$emit('close')">x</button> -->
      <div class="honor" v-if="popupCode == `POI-QW-1`">
        <div class="rz" style="overflow-y: scroll; overflow-x: hidden; height: 100%">
          <div class="horizontal">
            <img src="../../../assets/8be.webp" alt="" />
            <img src="../../../assets/9148.webp" alt="" />
            <img src="../../../assets/158.webp" alt="" />
          </div>
          <div class="horizontal">
            <img src="../../../assets/rz1.webp" alt="" srcset="" />
            <img src="../../../assets/rz2.webp" alt="" srcset="" />
            <img src="../../../assets/rz3.webp" alt="" srcset="" />
            <img src="../../../assets/rz4.webp" alt="" srcset="" />
            <img src="../../../assets/rz5.webp" alt="" srcset="" />
            <img src="../../../assets/rz6.webp" alt="" srcset="" />
          </div>
          <div class="horizontal">
            <img src="../../../assets/rz7.webp" alt="" srcset="" />
            <img src="../../../assets/rz8.webp" alt="" srcset="" />
            <img src="../../../assets/rz9.webp" alt="" srcset="" />
            <img src="../../../assets/rz10.webp" alt="" srcset="" />
            <img src="../../../assets/rz1.webp" alt="" srcset="" />
            <img src="../../../assets/rz1.webp" alt="" srcset="" />
          </div>
        </div>
      </div>
      <div class="bookshelf" v-else-if="popupCode == `POI-SF-1`">
        <div class="rz" style="overflow-y: scroll; overflow-x: hidden; height: 100%">
          <audio id="audio" ref="audio" autoplay :src="musicUrl"></audio>
          <ul>
            <li><p>茶叶店拓客朋友圈</p></li>
            <li>
              <p>博弈论</p>
              <img @click="mq3(1)" src="../../../assets/yin.webp" alt="" />
            </li>
            <li>
              <p>系统之美</p>
              <img @click="mq3(11)" src="../../../assets/yin.webp" alt="" />
            </li>
            <li>
              <p>谷歌的故事</p>
              <img @click="mq3(3)" src="../../../assets/yin.webp" alt="" />
            </li>
            <li>
              <p>谷歌方法</p>
              <img @click="mq3(4)" src="../../../assets/yin.webp" alt="" />
            </li>
            <li>
              <p>茶悟人生</p>
              <img @click="mq3(2)" src="../../../assets/yin.webp" alt="" />
            </li>
            <li>
              <p>中华茶文化概论</p>
              <img @click="mq3(13)" src="../../../assets/yin.webp" alt="" />
            </li>
            <li><p>灰度决策</p></li>
            <li>
              <p>重构-新商业模式</p>
              <img @click="mq3(12)" src="../../../assets/yin.webp" alt="" />
            </li>
            <li>
              <p>开启商业与未来的秘密</p>
              <img @click="mq3(7)" src="../../../assets/yin.webp" alt="" />
            </li>
            <li>
              <p>稻盛和夫阿米巴经营实践</p>
              <img @click="mq3(5)" src="../../../assets/yin.webp" alt="" />
            </li>
            <li><p>okr做最重要的事</p></li>
            <li><p>okr工作法</p></li>
            <li>
              <p>图解茶经</p>
              <img @click="mq3(10)" src="../../../assets/yin.webp" alt="" />
            </li>
            <li>
              <p>熵增定律</p>
              <img @click="mq3(9)" src="../../../assets/yin.webp" alt="" />
            </li>
            <li><p>复盘</p></li>
            <li><p>数字手册</p></li>
            <li><p>项目管理与领导力袖珍</p></li>
            <li>
              <p>具体数学</p>
              <img @click="mq3(6)" src="../../../assets/yin.webp" alt="" />
            </li>
            <li><p>数学应试技巧攻略</p></li>
            <li><p>商业模式新生代</p></li>
            <li><p>基于传感器开发者技巧</p></li>
            <li>
              <p>商业模式设计新生代</p>
              <img @click="mq3(8)" src="../../../assets/yin.webp" alt="" />
            </li>
            <li><p>管理类乱靠专业型硕士逻辑讲义</p></li>
            <li><p>成就解读本</p></li>
            <li><p>重构</p></li>
          </ul>
        </div>
      </div>
      <div class="tea" v-else-if="popupCode == `POI-TT-1`" style="max-height: 49em">
        <d-players ref="dPlayers" />
      </div>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import dPlayer from "@/components/player/dPlayer.vue"; //直播
import dPlayers from "@/components/player/dPlayers.vue"; //直播
import ezuikitJs from "@/components/player/ezuikitJs.vue"; //视频监控

import { defineComponent, getCurrentInstance } from "vue";
export default defineComponent({
  name: "PopupView",
  setup() {
    const { proxy }: any = getCurrentInstance();
    return {
      proxy: proxy,
      visible: true,
      title: "雅恒科技",
      musicUrl: "",
      musicList: [
        { src: require("../../../assets/mp3/1.mp3") },
        { src: require("../../../assets/mp3/2.mp3") },
      ],
    };
  },
  created() {
    this.onOpen();
  },
  methods: {
    /*
    播放音乐
    */
    mq3(e) {
      console.log(this.musicUrl);
       this.musicUrl = require("../../../assets/mp3/" + e + ".mp3");
      var audio: any = this.$refs.audio;
      audio.src = this.musicUrl;
      // console.log(audio);
       console.log(this.musicUrl);
      // audio.play();
      console.log(audio);
        // audio.load();
    },
    /**
     * 打开时
     */
    onOpen() {
      switch (this.popupCode) {
        case "POI-TV-1":
          this.title = "公司大屏直播";
          break;
        case "POI-MC-1":
          this.title = "公司环境直播";
          break;
        case "POI-QW-1":
          this.title = "荣誉";
          break;
        case "POI-SF-1":
          this.title = "书架";
          break;
        case "POI-TT-1":
          this.title = "茶几";
          break;
        default:
          break;
      }
    },
    /**
     * 关闭时
     */
    close() {
      switch (this.popupCode) {
        case "POI-TV-1":
          this.proxy.$refs.dPlayer.close();
          break;
        default:
          break;
      }

      this.visible = false;
      this.proxy.$emit("close");
    },
  },
  components: {
    "d-player": dPlayer,
    "ezuikit-js": ezuikitJs,
    "d-players": dPlayers,
  },
  props: ["popupCode"],
});
</script>

<style lang="scss" scoped>
.popup-1- {
  // position: fixed;
  // background-color: #ffffff;
  // width: 75em;
  // height: auto;
  min-height: 40em;
  max-height: 70em;
  // top: 5em;
  // left: 22em;
  // border: 1px solid #ccc;
  // border-radius: 10px;
  // .btn-close- {
  //   position: absolute;
  //   top: 1em;
  //   right: 1em;
  //   width: 2em;
  //   height: 2em;
  //   z-index: 1001;
  // }
  .tv,
  .camera,
  .honor,
  .bookshelf,
  .tea {
    width: 100%;
    height: 745px;
    margin: 10px auto 0;
    // background-color: aqua;
  }
  .honor,
  .bookshelf {
    .rz {
      width: 100%;
      height: 100%;
      .horizontal:nth-child(1) {
        width: 80%;
        margin: auto;
        img:nth-child(2) {
          width: 280px;
        }
        img:nth-child(1),
        img:nth-child(3) {
          width: 200px;
        }
      }
      .horizontal {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          display: block;
          margin: auto;
          width: 150px;
          // height: 540px;
        }
      }
      ul {
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        list-style-type: none;
        overflow: hidden;
        li:hover {
          background-color: rgb(130, 223, 223);
        }
        li {
          width: 44%;
          display: flex;
          // flex-direction: column;
          height: 100%;
          margin-right: 4%;
          border: 1px solid rgb(131, 131, 131);
          margin: 5px auto;
          p {
            width: auto;
            padding-left: 20px;
            height: 30px;
            line-height: 30px;
            font-size: 18px;
            // color: rgb(177, 226, 43);
          }
          img {
            // display: block;
            // margin: auto;
            padding-left: 10px;
            width: 30px;
            cursor: pointer;
          }
        }
      }
    }
  }
  h3 {
    padding-top: 15px;
  }
  .tea {
    p {
      width: 80%;
      display: block;
      margin: 80px auto;
      font-size: 24px;
    }
    img {
      width: 80%;
      margin: 180px auto 20px;
      display: block;
    }
  }
}
</style>
