
import DPlayer from "dplayer";

import { defineComponent, getCurrentInstance } from "vue";
export default defineComponent({
  name: "dPlayer",
  setup() {
    const { proxy }: any = getCurrentInstance();
    let dp: any = "";
    return {
      proxy,
      dp,
    };
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    let container = document.getElementById("dplayer");
    const dp = new DPlayer({
      container: container,
      video: {
        // url: "https://d1--cn-gotcha208.bilivideo.com/live-bvc/532934/live_50329118_9516950_1500/index.m3u8?expires=1629434550&len=0&oi=2028359561&pt=web&qn=0&trid=10075ef40241c42f49d69ac5299c52969c31&sigparams=cdn,expires,len,oi,pt,qn,trid&cdn=cn-gotcha08&sign=d4a002eeced8e2793c9cfd7709e07e1c&p2p_type=1&src=9&sl=9&free_type=0&sk=c9c6154426932efa80d25af02e87a3bd",
        // url: "rtmp://192.168.1.251/red5Demo/Yaheen/yaheen",
            // url: "rtmp://192.168.1.251/red5Demo/respberry/",
         url: require("../../assets/mp4/sp4.mp4"),
        type: "hls",
      },
      contextmenu: [],
      live: true,
      autoplay: true,
    });
    this.dp = dp;
  },
  methods: {
    close() {
      this.dp.destroy();
    },
  },
  components: {},
});
