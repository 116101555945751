
import dPlayer from "@/components/player/dPlayer.vue"; //直播
import dPlayers from "@/components/player/dPlayers.vue"; //直播
import ezuikitJs from "@/components/player/ezuikitJs.vue"; //视频监控

import { defineComponent, getCurrentInstance } from "vue";
export default defineComponent({
  name: "PopupView",
  setup() {
    const { proxy }: any = getCurrentInstance();
    return {
      proxy: proxy,
      visible: true,
      title: "雅恒科技",
      musicUrl: "",
      musicList: [
        { src: require("../../../assets/mp3/1.mp3") },
        { src: require("../../../assets/mp3/2.mp3") },
      ],
    };
  },
  created() {
    this.onOpen();
  },
  methods: {
    /*
    播放音乐
    */
    mq3(e) {
      console.log(this.musicUrl);
       this.musicUrl = require("../../../assets/mp3/" + e + ".mp3");
      var audio: any = this.$refs.audio;
      audio.src = this.musicUrl;
      // console.log(audio);
       console.log(this.musicUrl);
      // audio.play();
      console.log(audio);
        // audio.load();
    },
    /**
     * 打开时
     */
    onOpen() {
      switch (this.popupCode) {
        case "POI-TV-1":
          this.title = "公司大屏直播";
          break;
        case "POI-MC-1":
          this.title = "公司环境直播";
          break;
        case "POI-QW-1":
          this.title = "荣誉";
          break;
        case "POI-SF-1":
          this.title = "书架";
          break;
        case "POI-TT-1":
          this.title = "茶几";
          break;
        default:
          break;
      }
    },
    /**
     * 关闭时
     */
    close() {
      switch (this.popupCode) {
        case "POI-TV-1":
          this.proxy.$refs.dPlayer.close();
          break;
        default:
          break;
      }

      this.visible = false;
      this.proxy.$emit("close");
    },
  },
  components: {
    "d-player": dPlayer,
    "ezuikit-js": ezuikitJs,
    "d-players": dPlayers,
  },
  props: ["popupCode"],
});
