
// simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
// 视频上方头部控件
//header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
//   plugin: ["talk"], // 加载插件，talk-对讲
// 视频下方底部控件
// footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
// audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
// openSoundCallBack: data => console.log("开启声音回调", data),
// closeSoundCallBack: data => console.log("关闭声音回调", data),
// startSaveCallBack: data => console.log("开始录像回调", data),
// stopSaveCallBack: data => console.log("录像回调", data),
// capturePictureCallBack: data => console.log("截图成功回调", data),
// fullScreenCallBack: data => console.log("全屏回调", data),
// getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
import EZUIKit from "ezuikit-js";
import $request from "@/services/request";
import { defineComponent, getCurrentInstance } from "vue";
export default defineComponent({
  name: "dPlayer",
  props: {
    msg: String,
  },
  setup() {
    const { proxy }: any = getCurrentInstance();
    return {
      proxy,
    };
  },
  data() {
    return {
      width: 1150,
      height: 600,
    };
  },
  created() {},
  mounted() {
    $request.getLappToken({
      data: {},
      method: "post",
      chain: false,
      success: (result: any) => {
        if (result.result) {
          let [id, accessToken, url, template] = [
            "video-container",
            result.data.token,
            "ezopen://XPDKEN@open.ys7.com/G00773647/1.live",
            "simple",
          ];
          this.loadingPlayer(id, accessToken, url, template);
        } else {
          console.log(result);
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  },
  methods: {
    loadingPlayer(
      id: string,
      accessToken: string,
      url: string,
      template: string
    ) {
      let player = new EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: id,
        accessToken: accessToken,
        url: url,
        template: template,
        width: this.width,
        height: this.height,
      });
      console.log(player);
    },
  },
  components: {},
});
